import React from "react"

// Libraries
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { useTranslation } from "react-i18next"

// Layout
import LayoutPrimary from "layouts/layout-primary"

// Utils
import { colors } from "utils/variables"

// Components
import Seo from "components/seo/"
import ContextConsumer from "components/context/"

// Sections
import Hero from "sections/brand-narrative-workshop/hero"
import Description from "sections/brand-narrative-workshop/description"
import WhatIsBnw from "sections/brand-narrative-workshop/what-is-bnw"
import CallToAction from "sections/brand-narrative-workshop/call-to-action"
import Testimonials from "sections/brand-narrative-workshop/testimonials"
import CaseStudy from "sections/brand-narrative-workshop/case-study"
import Carousel from "sections/brand-narrative-workshop/carousel"
import AddVoice from "sections/brand-narrative-workshop/add-voice"

const BrandNarrativeWorkshop = props => {
  const { pageContext } = props

  // Hooks
  const { t } = useTranslation()

  return (
    <LayoutPrimary menuColor={colors.midnight}>
      <ContextConsumer>
        {({ data, set }) => {
          if (data.useYellowFooter) {
            set({ useYellowFooter: false })
          }
          if (data.darkMenu) {
            set({ darkMenu: false })
          }
          if (!data.showFooter) {
            set({ showFooter: true })
          }
          if (!data.simplifiedFooter) {
            set({ simplifiedFooter: true })
          }
        }}
      </ContextConsumer>
      <Seo
        title={t("Brand Narrative Workshop - Indicius Design Agency")}
        description={t(
          "A custom online branding workshop for companies who want to define their brand's story and identity in 3 hours."
        )}
      />
      <Hero />
      <WhatIsBnw />
      <Description />
      <Carousel language={pageContext.language} />
      <AddVoice />
      <Testimonials />
      <CaseStudy url="/work/kapital" />
      <CallToAction />
    </LayoutPrimary>
  )
}

BrandNarrativeWorkshop.propTypes = {
  pageContext: PropTypes.shape({ language: PropTypes.string.isRequired })
    .isRequired,
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default BrandNarrativeWorkshop
