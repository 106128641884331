import React from "react"

// Libraries
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby-plugin-react-i18next"
import { useTranslation, Trans } from "gatsby-plugin-react-i18next"

// Utils
import breakpoint from "utils/breakpoints"
import { colors } from "utils/variables"

// Components
import Container from "components/container/"
import LinkWithIcon from "components/link-with-icon"

// Icon
import IconCursor from "assets/icons/cursor-open.svg"

const StyledCaseStudy = styled(Link)`
  display: block; 
  padding-top: 48px;
  position: relative;
  background-color: ${colors.supernova};
  transition: all 0.3s ease;
  color: ${colors.midnight};
  cursor: url("${IconCursor}") 45 45, default;
  
  ${breakpoint.medium`
    padding-top: 80px;
  `}
  
  &:hover{
    background-color: ${colors.midnight};
    color: white;
  }

  .container--small {
    max-width: 736px;
    margin-bottom: 48px;
  }
`

const CaseStudy = props => {
  const { url } = props
  const { t } = useTranslation()
  return (
    <StyledCaseStudy to={url}>
      <div>
        <Container className="container--small ">
          <LinkWithIcon
            to="/work/kapital"
            className="mb-3 mb-sm-4"
            iconColor={colors.indiblue}
            text={t("Visit Case Study")}
          />
          <h2 className="mb-3">
            <Trans>See the power of this</Trans>
            <br />
            <Trans>workshop in practice</Trans>
          </h2>
          <p>
            <Trans>
              How the Brand Narrative Workshop helped Paraguay’s first 100%
              online bank build a stand-out brand.
            </Trans>
          </p>
        </Container>
      </div>

      <Container className="text-center next-case-study-wrapper">
        <StaticImage
          src="../../assets/images/services/brand-narrative-workshop/kapital-cs.png"
          alt=""
        />
      </Container>
    </StyledCaseStudy>
  )
}

export default CaseStudy
