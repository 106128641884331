import React from "react"

// Libraries
import styled from "styled-components"

// Utils
import breakpoint from "utils/breakpoints/"

// Components
import Container from "components/container/"

// Icons
import IconCheck from "assets/icons/icon-check-handwritten.svg"
import IconDecoration from "assets/icons/brand-narrative-workshop/icon-description.svg"
import { Trans } from "react-i18next"

const StyledDescription = styled.section`
  padding: 60px 0 160px;
  position: relative;

  ${breakpoint.medium`
    padding: 152px 0 200px 0;
  `}

  h2 {
    margin-bottom: 40px;
  }

  li {
    display: flex;

    &::before {
      content: url("${IconCheck}");
      position: relative;
      top: 1px;
      display: inline-block;
      margin-right: 16px;
    }

    p {
      display: inline;
    }
  }

  .description__deco {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;

    ${breakpoint.large`
      width: 320px;
      height: auto;
      transform: rotate(-90deg);
      right: -90px;
      bottom: 154px;
      left: auto;
    `}
  }
`

const Description = () => {
  const firstColumn = [
    <p className="paragraph--small">
      <Trans>Because it helps you better</Trans>{" "}
      <span className="font-weight--700">
        <Trans>understand and communicate</Trans>
      </span>{" "}
      <Trans>with your clients.</Trans>
    </p>,
    <p className="paragraph--small">
      <Trans>Because it helps you understand and communicate your</Trans>{" "}
      <span className="font-weight--700">
        <Trans>value proposition.</Trans>
      </span>
    </p>,
    <p className="paragraph--small">
      <Trans>Because it defines your</Trans>{" "}
      <span className="font-weight--700">
        <Trans>brand’s personality</Trans>
      </span>{" "}
      <Trans>as a reference point to start designing.</Trans>
    </p>,
  ]

  const secondColumn = [
    <p className="paragraph--small">
      <Trans>Because it</Trans>{" "}
      <span className="font-weight--700">
        <Trans>aligns your team</Trans>
      </span>{" "}
      <Trans>
        so they have common language to talk about the brand and make
        brand-aligned decisions.
      </Trans>
    </p>,
    <p className="paragraph--small">
      <Trans>Because it helps you position yourself in comparison to the</Trans>{" "}
      <span className="font-weight--700">
        <Trans>competition</Trans>
      </span>
      .
    </p>,
  ]

  const content = [firstColumn, secondColumn]

  return (
    <StyledDescription className="bg--supernova color--stout">
      <Container>
        <div className="row justify-content-center">
          <div className="col-12 col-md-10">
            <div className="row">
              <div className="col-12 col-sm-6 col-lg-6">
                <h2>
                  <Trans>Why should you do this workshop?</Trans>
                </h2>
              </div>

              <div className="row">
                {content.map(column => (
                  <div className="col-12 col-sm-6" key={column}>
                    <ul>
                      {column.map(item => (
                        <li key={item} className="mb-4">
                          {item}
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </Container>

      <img src={IconDecoration} className="description__deco" alt="" />
    </StyledDescription>
  )
}

export default Description
