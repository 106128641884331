import React from "react"

// Libraries
import styled from "styled-components"
import { Trans, useTranslation } from "react-i18next"

// Utils
import breakpoint from "utils/breakpoints/"

// Components
import Container from "components/container/"
import ButtonLink from "components/button"
import Lottie from "components/lottie-observed/"

// Animation
import Animation from "assets/animations/brand-narrative-workshop/animation-hero.json"
import { colors } from "utils/variables"

const StyledHero = styled.section`
  padding: 102px 0 48px 0;

  ${breakpoint.small`
    padding: 192px 0 120px 0;
  `}

  h1 {
    margin-bottom: 40px;
    ${breakpoint.medium`
      margin-bottom: 24px;
    `}
    span {
      font-family: "Archivo";
    }
  }

  .button {
    margin-top: 40px;
    // display: inline-block;
  }
`

const Hero = () => {
  const { t } = useTranslation()

  return (
    <StyledHero className="bg--midnight color--white">
      <Container>
        <div className="row align-items-center justify-content-between">
          <div className="col-12 col-sm-8 col-md-6">
            <h4 className="mb-3 color--supernova font-weight--700 text-uppercase">
              <Trans>Our Services</Trans>
            </h4>

            <h1>
              <Trans>Brand Narrative</Trans>
              <br />
              <span className="color--supernova font-weight--500">
                <Trans>Workshop</Trans>
              </span>
            </h1>

            <p className="paragraph--small">
              <Trans>Every brand has its own story. What’s yours?</Trans>
            </p>
            <br />
            <p className="paragraph--small">
              <Trans>
                Our online co-creation workshop helps you build your story and
                tell it to the world. Let’s do it together.
              </Trans>
            </p>
            <ButtonLink
              to="/contact"
              text={t("Contact us")}
              backgroundColor={colors.supernova}
              color={colors.stout}
              hoverBackgroundColor={colors.white}
              className="button"
            />
          </div>

          <div className="col-12 col-md-4 d-none d-md-block">
            <Lottie animation={Animation} />
          </div>
        </div>
      </Container>
    </StyledHero>
  )
}

export default Hero
