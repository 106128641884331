import React from "react"

// Libraries
import styled from "styled-components"
import { Trans } from "react-i18next"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables"

// Components
import Container from "components/container/"
import IconAddVoice from "assets/icons/brand-narrative-workshop/icon-add-voice.inline.svg"

const StyledAddVoice = styled.section`
  padding: 80px 0;

  ${breakpoint.medium`
  padding: 160px 0;
`}

  h1 {
    margin-bottom: 40px;
    color: ${colors.stout};
  }

  p {
    color: ${colors.stout};
    margin-bottom: 32px;

    ${breakpoint.medium`
    align-text: start;
    margin-bottom: 24px;
    `}
  }
`

const AddVoice = () => {
  return (
    <StyledAddVoice className="bg--cream">
      <Container>
        <div className="row justify-content-between">
          <div className="col-12 col-md-6 row align-items-center">
            <h1>
              <Trans>Add voice!</Trans>
            </h1>
            <div>
              <p>
                <Trans>
                  This workshop forms a foundation to continue building your
                  brand. After completing the workshop, you can choose
                </Trans>{" "}
                <span className="font-weight--700">
                  <Trans>to add a Brand Messaging Guide.</Trans>
                </span>
              </p>
              <p>
                <Trans>
                  This document–which will help you generate copy now and years
                  in the future–includes brand voice and tone, messaging for
                  different audiences, grammar rules, voice of customer
                  research, and more.
                </Trans>
              </p>
            </div>
          </div>
          <div className="col-5 col-md-5">
            <IconAddVoice />
          </div>
        </div>
      </Container>
    </StyledAddVoice>
  )
}

export default AddVoice
