import React from "react"

// Libraries
import styled from "styled-components"
import PropTypes from "prop-types"

import Icon from "assets/images/services/brand-narrative-workshop/testimonials/quote-icon.inline.svg"

// Utils
import breakpoints from "utils/breakpoints"
import { colors } from "utils/variables"

const StyledDialog = styled.div`
  padding: 24px;
  color: white;
  background-color: ${colors.indiblue};
  border-radius: 18px;
  text-align: start;
  position: relative;
  margin: 0 auto;
  box-sizing: border-box;

  ${breakpoints.medium`
    max-width: 352px;
    width: 100%;
  `}

  svg {

    position: absolute;
    ${breakpoints.small`
      ${props =>
        props.x === "left" &&
        props.y === "bottom" &&
        `  
        left: 60%;
        bottom: -33px;
        transform: rotate(90deg) scaleX(-1);
      `}

      ${props =>
        props.x === "right" &&
        props.y === "bottom" &&
        `
        left: 20%;
        bottom: -33px;
        transform: rotate(90deg) scaleX(-1);
      `}

      ${props =>
        props.x === "left" &&
        props.y === "top" &&
        `
        top: auto;
        left: 50%;
        bottom: -33px;
        transform: rotate(-90deg);
      `}
    `}

    ${props =>
      props.x === "left" &&
      props.y === "bottom" &&
      `  
      left: -24px;
      bottom: 24px
    `}

    ${props =>
      props.x === "right" &&
      props.y === "bottom" &&
      `
        right: -24px;
        bottom: 24px;
        transform: rotate(180deg);
        transform: rotateY(3.142rad);
    `}

    ${props =>
      props.x === "left" &&
      props.y === "top" &&
      `
        left: -24px;
        top: 24px;
        transform: rotateX(3.142rad);
    `}
  }
`

const DialogeBox = props => {
  const { x, y, quote, client, className } = props

  return (
    <StyledDialog x={x} y={y} className={className}>
      <p className="mb-4 paragraph--small font-weight--500">{quote}</p>
      <p className="paragraph--small">{client}</p>
      <Icon />
    </StyledDialog>
  )
}

DialogeBox.propTypes = {
  x: PropTypes.oneOf(["left", "right", "center"]).isRequired,
  y: PropTypes.oneOf(["top", "bottom"]).isRequired,
  quote: PropTypes.string.isRequired,
  client: PropTypes.string.isRequired,
  className: PropTypes.string,
}

DialogeBox.defaultProps = {
  className: null,
}

export default DialogeBox
