import React, { useState } from "react"

// Libraries
import styled from "styled-components"
import { Trans, useTranslation } from "react-i18next"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables"

// Components
import Container from "components/container/"
import ButtonLink from "components/button/"
import Observer from "components/observer/"

// Icons
import IconCTA from "assets/icons/brand-narrative-workshop/icon-CTA.inline.svg"
import YellowCircle from "assets/icons/footer-yellow-circle.inline.svg"
import CyanCircle from "assets/icons/footer-cyan-circle.inline.svg"

const StyledCallToAction = styled.section`
overflow: hidden;
    ${breakpoint.large`
      padding: 188px 0;
      position: relative; 
    `}

    ${breakpoint.wide`
    padding: 0; 
  `}

  h2 {
    margin-bottom: 16px;
    margin-top: 80px;
    color: ${colors.white};

    ${breakpoint.small`
    margin-top: 80px;
    color: ${colors.supernova};
  `}

    ${breakpoint.large`
    margin-top: 0;
  `}
  }

  .interaction__container {
    margin-top: 94px;
    margin-bottom: 16px;
    position: relative;

    ${breakpoint.small`
    margin-top: 0;
  `}

    ${breakpoint.medium`
    margin-bottom: 0;
  `}
  }

  .text {
    display: none; 
    

    ${breakpoint.small`
      display: block;
      margin-bottom: 40px;
  `}
  }

  svg {
    ${breakpoint.large`
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
  `}

    ${breakpoint.wide`
      position: relative; 
    `}
  }

  .button {

    ${breakpoint.small`
    display: inline-block;
    margin-bottom: 80px;
`}
  }
}
`

const FooterIcons = styled.div`
  width: 90px; // Because it's rotated, this is height
  height: 130px; // Because it's rotated, this is width
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: -36px;
  left: calc(100% - 82px);
  transform: rotate(90deg);

  ${breakpoint.small`
  display: none;
  `}

  svg {
    transition: all 1.3s ease;

    &:first-child {
      transform: ${props =>
        props.isVisible ? "translateY(0)" : "translateY(-80px)"};
      z-index: 10;

      // circle {
      //   stroke: ${props => (props.yellow ? colors.cream : "")};
      // }
    }

    &:last-child {
      // top: -18%;
      transform: ${props =>
        props.isVisible ? "translateY(-28%)" : "translateY(0)"};
      z-index: 9;

      ${breakpoint.medium`
        top: -94px;
        transform: ${props =>
          props.isVisible ? "translateY(0)" : "translateY(120px)"};
      `}
    }
  }
`

const CallToAction = () => {
  const [isVisible, setVisibility] = useState(false)

  const handleVisibility = () => {
    if (!isVisible) {
      setVisibility(true)
    }
  }
  const { t } = useTranslation()

  return (
    <StyledCallToAction className="bg--midnight color--white">
      <Container>
        <Observer onVisible={handleVisibility} threshold="1.0">
          <div className="row justify-content-between align-items-center flex-sm-column-reverse flex-md-column-reverse flex-lg-row flex-xl-row flex-md-row">
            <div className="col-12 col-md-6 col-lg-4">
              <h2 className="alt">
                <Trans>Let’s write your story together</Trans>
              </h2>
              <p className="text">
                <Trans>
                  Tell us a little more and we’ll get the ball rolling
                </Trans>
              </p>
              <div className="interaction__container">
                <ButtonLink
                  to="/contact"
                  text={t("Let’s chat")}
                  backgroundColor={colors.supernova}
                  hoverBackgroundColor={colors.white}
                  color={colors.stout}
                  className="button secondary"
                />
                <FooterIcons isVisible={isVisible}>
                  <YellowCircle />
                  <CyanCircle />
                </FooterIcons>
              </div>
            </div>
            <div className="col-12 col-md-7 d-none d-sm-block">
              <IconCTA />
            </div>
          </div>
        </Observer>
      </Container>
    </StyledCallToAction>
  )
}

export default CallToAction
