import React from "react"

// Libraries
import styled from "styled-components"
import { Trans } from "react-i18next"

// Utils
import breakpoint from "utils/breakpoints/"

// Components
import Container from "components/container/"

// Icons
import IconQuestionnaire from "assets/icons/brand-narrative-workshop/icon-questionnaire.inline.svg"

const StyledWhatIsBnw = styled.section`
  padding: 80px 0;
  text-align: center;

  ${breakpoint.medium`
    padding: 128px 0 104px 0;
    text-align: start;      
`}

  h2 {
    margin-bottom: 40px;

    ${breakpoint.medium`
      margin-bottom: 24px;     
`}
  }

  p {
    margin-bottom: 40px;

    ${breakpoint.medium`
      margin-bottom: 49px;     
  `}
  }

  svg {
    flex-shrink: 1;
    margin-bottom: 20px;
  }
`

const WhatIsBnw = () => (
  <StyledWhatIsBnw>
    <Container>
      <div className="row align-items-center flex-column">
        <div className="col-12 col-md-5">
          <h2 className="color--midnight text-center">
            <Trans>What is the Brand Narrative Workshop?</Trans>
          </h2>
        </div>
        <div className="col-12 col-md-8">
          <p className="paragraph--small text-center">
            <Trans>
              A brand is like a person: it has an identity, personality, purpose
              and unique story. In just 3 hours we’ll help you define these
              elements of your brand, to build a solid foundation for EVERYTHING
              your company produces in the future.
            </Trans>
          </p>
        </div>
        <div className="col-12 col-md-10">
          <div className="row">
            <IconQuestionnaire />
          </div>
        </div>
      </div>
    </Container>
  </StyledWhatIsBnw>
)

export default WhatIsBnw
