import React, { useRef } from "react"

// Libraries
import PropTypes from "prop-types"
import styled from "styled-components"
import { Trans } from "react-i18next"
import Slider from "react-slick"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

// Styles
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

// Utils
import breakpoint from "utils/breakpoints/"

// Components
import Container from "components/container/"
import { graphql, useStaticQuery } from "gatsby"

// Icons
import IconArrowLeft from "assets/icons/brand-narrative-workshop/icon-arrow-left.inline.svg"
import IconArrowRight from "assets/icons/brand-narrative-workshop/icon-arrow-right.inline.svg"

const StyledCarousel = styled.section`
  padding: 80px 0;

  .carousel__title {
    margin-bottom: 40px;
    text-align: center;

    ${breakpoint.medium`
      margin-bottom: 32px;
      text-align: left;
    `}
  }

  .carousel__controls {
    button {
      cursor: pointer;
    }
  }

  .carousel {
    padding: 0 16px;

    ${breakpoint.small`
      padding: 0 48px;
    `}

    ${breakpoint.large`
      padding: 0;
    `}
    
    .slick-list {
      ${breakpoint.large`
        padding-left: calc((100vw - 1120px) / 2);
      `}
    }

    .slick-list {
      margin: 0 -16px;
    }

    .slick-slide {
      padding: 0 16px;
    }
  }
`

const Carousel = props => {
  const { language } = props

  // Hooks
  const ref = useRef()

  let {
    en: { nodes: en },
    es: { nodes: es },
  } = useStaticQuery(graphql`
    query {
      en: allFile(
        filter: {
          relativeDirectory: {
            eq: "services/brand-narrative-workshop/carousel/en"
          }
        }
      ) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(width: 640, height: 356, quality: 100)
          }
        }
      }

      es: allFile(
        filter: {
          relativeDirectory: {
            eq: "services/brand-narrative-workshop/carousel/es"
          }
        }
      ) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(width: 640, height: 356, quality: 100)
          }
        }
      }
    }
  `)

  en = en.sort((a, b) => a.name.split("-")[2] - b.name.split("-")[2])
  es = es.sort((a, b) => a.name.split("-")[2] - b.name.split("-")[2])

  const carouselSettings = {
    infinite: false,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 1199,
        settings: "unslick",
      },
    ],
  }

  const prev = () => {
    if (ref.current) {
      ref.current.slickPrev()
    }
  }

  const next = () => {
    if (ref.current) {
      ref.current.slickNext()
    }
  }

  return (
    <StyledCarousel>
      <Container>
        <div className="carousel__title">
          <div className="row align-items-end justify-content-between">
            <div className="col-12 col-md-6">
              <h3>
                <Trans>Take a look at what a workshop report looks like</Trans>
              </h3>
            </div>

            <div className="d-none d-lg-block col-2">
              <div className="carousel__controls">
                <button type="button" onClick={prev}>
                  <IconArrowLeft />
                </button>
                <button type="button" onClick={next}>
                  <IconArrowRight />
                </button>
              </div>
            </div>
          </div>
        </div>
      </Container>

      <Slider
        ref={s => (ref.current = s)}
        className="carousel"
        {...carouselSettings}
      >
        {language === "en"
          ? en.map(image => (
              <GatsbyImage
                className="mb-4 mb-lg-0"
                image={getImage(image)}
                alt=""
              />
            ))
          : es.map(image => (
              <GatsbyImage
                className="mb-4 mb-lg-0"
                image={getImage(image)}
                alt=""
              />
            ))}
      </Slider>
    </StyledCarousel>
  )
}

Carousel.propTypes = {
  language: PropTypes.oneOf(["es", "en"]).isRequired,
}

export default Carousel
