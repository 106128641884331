import React from "react"

// Libraries
import styled from "styled-components"
import { useTranslation, Trans } from "react-i18next"

// Components
import Testimonial from "components/brand-narrative-testimonial.js"
import Container from "components/container"
import breakpoints from "utils/breakpoints"

const StyledTestimonial = styled.section`
  text-align: center;
  padding: 80px 0;

  ${breakpoints.large`
    padding: 160px 0;
  `}

  .title {
    margin-bottom: 80px;
    max-width: 544px;
    width: 100%;
  }

  .row {
    margin: 0;
    padding: 0;
    ${breakpoints.large`
      .col-12 {
        padding-right: 0;
        padding-left: 0;
      }
    `}
  }

  .testimonial {
    margin-bottom: 32px;

    ${breakpoints.small`
      margin-bottom: 0;
    `}
  }
`

const Testimonials = () => {
  const { t } = useTranslation()
  return (
    <StyledTestimonial>
      <Container>
        <div className="row justify-content-center">
          <div className="col-12 col-sm-6">
            <h3 className="text-align-center title font-weight--500">
              <Trans>What do our clients have to say about the workshop?</Trans>
            </h3>
          </div>
          <div className="row align-items-sm-end">
            <div className="testimonial col-12 col-sm-4">
              <Testimonial
                quote={t(
                  "“It was a very helpful process for Stonehaven to think conceptually about our brand positioning and narrative which informed our downstream workflows with Indicius”"
                )}
                client={"David Frank - Stonehaven"}
                x={"left"}
                y={"bottom"}
              />
            </div>
            <div className="testimonial col-12 col-sm-4">
              <Testimonial
                quote={t(
                  "“It has been a very powerful tool for us that we still use as a strategic resource to this day.”"
                )}
                client={"Alex Phelan - Merchadise"}
                x={"right"}
                y={"bottom"}
              />
            </div>
            <div className="col-12 col-sm-4">
              <Testimonial
                quote={t(
                  "“It gave Kapital a sense of direction, a compass to follow along a long and uncertain path. At the end of the workshop, Kapital had clearly identified its mission, values, reason for existing and goals to be met.”"
                )}
                client={"Maximiliano Britez - Kapital"}
                x={"left"}
                y={"top"}
              />
            </div>
          </div>
          {/* <div className="col-12 col-md-10 mt-4 d-none d-sm-block">
            <StaticImage src="../../assets/images/services/brand-narrative-workshop/testimonials/people-image.png" />
          </div> */}
        </div>
      </Container>
    </StyledTestimonial>
  )
}

export default Testimonials
